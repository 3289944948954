import React from 'react'

import styled, { css, withTheme } from 'styled-components'
import lang, * as l10n from '../../../../utils/l10n'
import { Accordion, Table } from 'mf-ui-components'
import { getPercentage, isBenchmarkEnabled, locale } from './libs'

const performanceValue = css`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
`
const PerformanceValueMoneyfarm = styled.div`
  ${performanceValue}
  color: ${({ theme }) => theme.tokens.chart.primary.main};
`
const PerformanceValueCompetitors = styled.div`
  ${performanceValue}
  color: ${({ theme }) => theme.tokens.chart.tertiary.main};
`
const TextAlignRight = styled.div`
  text-align: right;
`

export const PerformanceTable = React.memo(
  withTheme(({ data, theme }) => (
    <Accordion
      title={`${l10n.show_individual_years[lang]}`}
      titleColor={theme.tokens.accent.base.secondary.regular}
      titleTextStyles={{
        flexBasis: 'auto',
        fontWeight: 600,
        textDecoration: 'underline',
      }}
    >
      <Table
        data={data}
        headers={[
          {
            title: `${l10n.year[lang]}`,
            field: 'year',
          },
          {
            title: (
              <TextAlignRight>
                {l10n.table_moneyfarm_performance_header[lang]}
              </TextAlignRight>
            ),
            field: 'mfmPerformance',
            render: ({ mfmPerformance }) => (
              <TextAlignRight>
                <PerformanceValueMoneyfarm>
                  {getPercentage(locale, mfmPerformance * 100)}
                </PerformanceValueMoneyfarm>
              </TextAlignRight>
            ),
          },
          isBenchmarkEnabled() && {
            title: (
              <TextAlignRight>
                {l10n.table_competitors_performance_header[lang]}
              </TextAlignRight>
            ),
            field: 'benchmarkPerformance',
            render: ({ benchmarkPerformance }) => (
              <TextAlignRight>
                <PerformanceValueCompetitors>
                  {getPercentage(locale, benchmarkPerformance * 100)}
                </PerformanceValueCompetitors>
              </TextAlignRight>
            ),
          },
        ].filter(Boolean)}
      ></Table>
    </Accordion>
  ))
)

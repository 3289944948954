// @ts-expect-error
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030'
import React, { FC, useEffect, useState } from 'react'

import { Chart } from './Chart'
import { ControlsAndStats } from './ControlsAndStats'
import {
  Benchmark,
  RiskLevelData,
  extractBenchmarkData,
  findModelPortfolio,
  findRiskLevelBenchmark,
  getAnnualisedHistoricalPerformanceForPeriod,
  getDefaultAnnualisedPerformance,
  getFirstItemHistoricalPerformance,
  getTableData,
  prepareHistoricalPerformance,
} from '../../../../utils/modelPortfolios'
import { country, isBenchmarkEnabled } from './libs'
import rawlang, * as l10n from '../../../../utils/l10n'
import { PortfolioTitle, TableContainer } from './ChartSection.styles'
import { PerformanceTable } from './PerformanceTable'
import { useChartState } from './chartState'
import { InvestmentTypeControl } from './InvestmentTypeControl'
import { ModelPortfolio } from '../../../../types/ModelPortfolio'

const lang = rawlang as 'it' | 'en'

type Props = {
  modelPortfolios: RiskLevelData[]
  defaultModelPortfolio: RiskLevelData
  riskLevelData: RiskLevelData[]
}

export const ChartSection: FC<Props> = ({
  modelPortfolios,
  defaultModelPortfolio,
  riskLevelData,
}) => {
  const {
    riskLevel,
    complexityLevel,
    performanceStartDate,
    setPerformanceStartDate,
    investmentStyle,
  } = useChartState()

  const [currentModelPortfolio, setCurrentModelPortfolio] = useState<
    RiskLevelData
  >(defaultModelPortfolio)
  const [currentBenchmark, setCurrentBenchmark] = useState<Benchmark>()
  const [
    {
      annualisedPerformance: mfmAnnualisedPerformance,
      overallPerformance: mfmOverallPerformance,
    },
    setMfmPerformance,
  ] = useState<Benchmark>({
    annualisedPerformance: 0,
    overallPerformance: 0,
  })
  const [
    {
      annualisedPerformance: benchmarkAnnualisedPerformance,
      overallPerformance: benchmarkOverallPerformance,
    },
    setBenchmarkPerformance,
  ] = useState<Benchmark>({
    annualisedPerformance: 0,
    overallPerformance: 0,
  })

  useEffect(() => {
    const modelPortfolio =
      modelPortfolios.find(
        findModelPortfolio(
          country,
          riskLevel,
          Number(complexityLevel),
          investmentStyle
        )
      ) || defaultModelPortfolio

    if (!modelPortfolio) return

    setCurrentModelPortfolio(modelPortfolio)

    if (!performanceStartDate)
      setPerformanceStartDate(modelPortfolio.rawHistoricalPerformance[0].date)

    setMfmPerformance(
      getDefaultAnnualisedPerformance(
        modelPortfolio,
        performanceStartDate || modelPortfolio.rawHistoricalPerformance[0].date
      )
    )

    if (isBenchmarkEnabled()) {
      const benchmarkData = extractBenchmarkData(riskLevelData)

      const defaultBenchmark = findRiskLevelBenchmark(
        benchmarkData,
        modelPortfolio.riskLevel
      )

      setCurrentBenchmark(defaultBenchmark)

      setBenchmarkPerformance(
        getDefaultAnnualisedPerformance(
          (defaultBenchmark as unknown) as ModelPortfolio,
          performanceStartDate ||
            modelPortfolio.rawHistoricalPerformance[0].date
        )
      )
    }
  }, [
    complexityLevel,
    riskLevel,
    modelPortfolios,
    investmentStyle,
    performanceStartDate,
  ])

  const showPerformanceAtDate = (dateString?: string) => {
    const date = dateString
      ? moment(dateString).format('YYYY-MM-DD')
      : currentModelPortfolio.rawHistoricalPerformance[
          currentModelPortfolio.rawHistoricalPerformance.length - 1
        ].date

    const modelPerformance = currentModelPortfolio.rawHistoricalPerformance

    const modelPerformanceAtDate = modelPerformance.find(
      datum => datum.date === date
    )

    if (!modelPerformanceAtDate) return

    setMfmPerformance(
      getAnnualisedHistoricalPerformanceForPeriod(
        getFirstItemHistoricalPerformance(
          modelPerformance,
          performanceStartDate
        ),
        {
          date,
          performance: modelPerformanceAtDate.performance,
        }
      )
    )

    if (isBenchmarkEnabled() && currentBenchmark?.rawHistoricalPerformance)
      setBenchmarkPerformance(
        getAnnualisedHistoricalPerformanceForPeriod(
          getFirstItemHistoricalPerformance(
            currentBenchmark.rawHistoricalPerformance,
            performanceStartDate
          ),
          {
            date,
            performance:
              currentBenchmark.rawHistoricalPerformance.find(
                (datum: { date: string }) => datum.date === date
              )?.performance || 0,
          }
        )
      )
  }

  if (!performanceStartDate || !currentModelPortfolio) return null

  const mfmDataset = prepareHistoricalPerformance(
    currentModelPortfolio.rawHistoricalPerformance,
    performanceStartDate
  )

  let benchmarkDataset =
    isBenchmarkEnabled() &&
    performanceStartDate &&
    currentBenchmark?.rawHistoricalPerformance
      ? prepareHistoricalPerformance(
          currentBenchmark.rawHistoricalPerformance,
          performanceStartDate
        )
      : []

  return (
    <>
      <InvestmentTypeControl />

      <PortfolioTitle el="h5" styleName="title7">
        {l10n.portfolio[lang]} {riskLevel} {l10n.performance[lang]}
      </PortfolioTitle>
      <ControlsAndStats
        currentModelPortfolio={currentModelPortfolio}
        mfmOverallPerformance={mfmOverallPerformance || 0}
        mfmAnnualisedPerformance={mfmAnnualisedPerformance || 0}
        benchmarkOverallPerformance={benchmarkOverallPerformance}
        benchmarkAnnualisedPerformance={benchmarkAnnualisedPerformance}
      />
      <Chart
        onLineChartMouseMoveHandler={datum => showPerformanceAtDate(datum.x)}
        onLineChartMouseLeaveHandler={() => showPerformanceAtDate()}
        mfmDataset={mfmDataset}
        benchmarkDataset={benchmarkDataset}
      />

      <TableContainer>
        {currentModelPortfolio && (
          <PerformanceTable
            data={getTableData(currentModelPortfolio, currentBenchmark)}
          />
        )}
      </TableContainer>
    </>
  )
}

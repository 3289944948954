import { useEffect, useState } from 'react'
import {
  DEFAULT_COMPLEXITY_LEVEL,
  DEFAULT_RISK_LEVEL,
  RISK_LEVELS,
  country,
  retrieveModelPortfoliosData,
} from './libs'
import {
  RiskLevelData,
  filterHistoricalPerformance,
  findModelPortfolio,
} from '../../../../utils/modelPortfolios'

export function useModelPortfolios(): {
  modelPortfolios: RiskLevelData[]
  defaultModelPortfolio: RiskLevelData
  riskLevelData: RiskLevelData[]
  isLoaded: boolean
  error: unknown
} {
  const [modelPortfolios, setModelPortfolios] = useState<RiskLevelData[]>([])
  const [riskLevelData, setRiskLevelData] = useState<RiskLevelData[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [error, setError] = useState<unknown>()

  useEffect(() => {
    async function init() {
      try {
        const modelPortfoliosData = await retrieveModelPortfoliosData()

        const filteredData: RiskLevelData[] = modelPortfoliosData.map(
          filterHistoricalPerformance
        )

        setRiskLevelData(filteredData)

        setModelPortfolios(
          filteredData.filter(({ riskLevel }) =>
            RISK_LEVELS.includes(riskLevel)
          )
        )
      } catch (error) {
        setError(error)
      } finally {
        setIsLoaded(true)
      }
    }

    init()
  }, [])

  return {
    modelPortfolios,
    defaultModelPortfolio:
      modelPortfolios.find(
        findModelPortfolio(
          country,
          DEFAULT_RISK_LEVEL,
          DEFAULT_COMPLEXITY_LEVEL,
          'classic'
        )
      ) || modelPortfolios[0],
    riskLevelData,
    isLoaded,
    error,
  }
}

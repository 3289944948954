import styled from 'styled-components'

import { Card, LoadingSpinner } from 'mf-ui-components'

import { hideBlockAt, marginBreakpoints } from '../../../../utils/commonAttrs'
import { fromBreakpoint } from '../../../../utils/style/breakpoint'
import { spacing } from '../../../../utils/ui/spacing'

export const ModelPortfoliosContainer = styled.div<{
  hiddenAt: unknown
  marginBottom: unknown
}>`
  ${props => hideBlockAt(props)};
  ${props => marginBreakpoints(props)};
`

export const LoadingPane = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.tokens.interface.neutral.light};
  display: flex;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  border-radius: 4px;
`

export const Spinner = styled(LoadingSpinner)`
  display: block;
  margin: 0 auto;
`

export const TabWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`

export const StyledCard = styled(Card)`
  padding: ${spacing('md')};

  ${fromBreakpoint('md')} {
    padding: ${spacing('lg')};
  }
`

import styled, { css } from 'styled-components'

import { Text } from 'mf-ui-components'

export const performancePercentage = css`
  display: table-cell;
  font-weight: 600;
  font-family: ${({ theme }) => theme.typography.font.family.title};
  color: ${({ theme }) => theme.tokens.text.label.regular};
  height: 48px;
  line-height: 1;
  vertical-align: bottom;
`

export const AnnualisedPerformancePercentage = styled.div`
  ${performancePercentage}
  font-size: 32px;
`

export const OverallPerformancePercentage = styled.div`
  ${performancePercentage}
  font-size: 21px;
`

export const LineChartLegend = styled.h6`
  font-family: ${({ theme }) => theme.typography.font.family.title};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 20px;
`

export const lineChartLegendColor = css`
  width: 19px;
  height: 3px;
  margin: 28px 10px 0 0;
`

export const LineChartLegendColorMfm = styled.div`
${lineChartLegendColor}
  background-color: ${({ theme }) => theme.tokens.chart.primary.main};
`

export const LineChartLegendColorBenchmark = styled.div`
${lineChartLegendColor}
  background-color: ${({ theme }) => theme.tokens.chart.tertiary.main};
`

export const ControlsAndStatsContainer = styled.div`
  margin-bottom: 30px;
`

export const InvestmentRisk = styled(Text)`
  margin-bottom: '4px';
`

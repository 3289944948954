import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Accordion, Markdown, Text } from 'mf-ui-components'
import lang, * as l10n from '../../../../utils/l10n'

const MarkdownWrapper = styled.div`
  a {
    font-size: 13px;
    line-height: 1.31;
  }
`

export const KeyToTheFigures = withTheme(({ theme }) => (
  <div style={{ marginTop: '32px' }}>
    <Text el="h4" styleName="title8">
      {l10n.key_to_the_figures.title[lang]}
    </Text>
    <Text el="p" styleName="bodyRegular" variant="secondary">
      {l10n.key_to_the_figures.description[lang]}
    </Text>
    <Accordion
      title={l10n.key_to_the_figures.full_info[lang]}
      titleColor={theme.tokens.accent.base.secondary.regular}
      titleTextStyles={{
        flexBasis: 'auto',
        fontWeight: 600,
        textDecoration: 'underline',
      }}
      style={{ marginTop: '32px' }}
    >
      <MarkdownWrapper>
        <Markdown
          markdown={l10n.key_to_the_figures.full_info_paragraph[lang]}
        />
      </MarkdownWrapper>
    </Accordion>
  </div>
))

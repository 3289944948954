import { getAssetsBreakdownJsonUrl } from '../../../../utils/modelPortfolios'
import lang, * as rawl10n from '../../../../utils/l10n'
import {
  ModelPortfolioWithAssetsBreakdown,
  type AssetAllocationInfo,
} from '../../../../types/ModelPortfolio'
import { ASSETS_BREAKDOWN_SORTING } from './data'

const l10n = rawl10n as Record<string, any>

export const getPercentage = (locale: string, val: number | bigint) =>
  `${Intl.NumberFormat(locale).format(Number(Number(val).toFixed(1)))}%`

export const retrieveAssetsBreakdownData = async (): Promise<
  ModelPortfolioWithAssetsBreakdown[]
> => fetch(getAssetsBreakdownJsonUrl()).then((res) => res.json())

export function extractAssetsAllocationData(
  modelPortfolio: ModelPortfolioWithAssetsBreakdown,
  breakdownType: BreakdownType,
  locale: string
): AssetAllocationInfo[] {
  return modelPortfolio[breakdownType]
    .filter(({ id }) => ASSETS_BREAKDOWN_SORTING[breakdownType].includes(id))
    .map((assetClass) => ({
      ...assetClass,
      name:
        l10n.portfolio_asset_classes[assetClass.id]?.title?.[lang] ||
        assetClass.id,
      description:
        l10n.portfolio_asset_classes[assetClass.id]?.description?.[lang],
      weightFormatted: getPercentage(locale, assetClass.weight * 100),
    }))
    .toSorted(
      (assetA, assetB) =>
        ASSETS_BREAKDOWN_SORTING[breakdownType].indexOf(assetA.id) -
        ASSETS_BREAKDOWN_SORTING[breakdownType].indexOf(assetB.id)
    )
}

export type BreakdownType =
  | 'assetClasses'
  | 'sectorialAllocation'
  | 'geographicalAllocation'

export const COMPLEXITY_LEVEL = 1

export const RISK_LEVELS = [1, 2, 3, 4, 5, 6, 7]

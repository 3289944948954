import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'

import { DatePicker, Select, Text } from 'mf-ui-components'

import rawlang, * as l10n from '../../../../utils/l10n'
import { getPercentage, isBenchmarkEnabled } from './libs'
import { getLocaleFromCountry } from '../../../../utils/locale'
import {
  AnnualisedPerformancePercentage,
  InvestmentRisk,
  LineChartLegend,
  LineChartLegendColorBenchmark,
  LineChartLegendColorMfm,
  OverallPerformancePercentage,
  ControlsAndStatsContainer,
} from './ControlsAndStats.styles'
import { useChartState } from './chartState'
import { RiskLevelData } from '../../../../utils/modelPortfolios'

const lang = rawlang as 'it' | 'en'

type Props = {
  currentModelPortfolio: RiskLevelData
  mfmOverallPerformance: number
  mfmAnnualisedPerformance: number
  benchmarkOverallPerformance: number
  benchmarkAnnualisedPerformance: number
}

export const ControlsAndStats = ({
  currentModelPortfolio,
  mfmOverallPerformance,
  mfmAnnualisedPerformance,
  benchmarkOverallPerformance,
  benchmarkAnnualisedPerformance,
}: Props) => {
  const {
    complexityLevel,
    setComplexityLevel,
    performanceStartDate,
    setPerformanceStartDate,
    riskLevel,
    investmentStyle,
  } = useChartState()

  const locale = getLocaleFromCountry(process.env.GATSBY_COUNTRY)

  if (!currentModelPortfolio || !performanceStartDate) return null

  return (
    <ControlsAndStatsContainer>
      <Row justifyContent="between">
        <Col col={12} md={8} xl={6}>
          <Row>
            {process.env.GATSBY_COUNTRY === 'IT' && investmentStyle !== 'esg' && (
              <Col col={12} md={6} style={{ marginTop: '24px' }}>
                <InvestmentRisk el="p" styleName="label">
                  {l10n.investment[lang]}
                </InvestmentRisk>
                <Select
                  name="investment"
                  options={[
                    {
                      value: '1',
                      label: `${l10n.complexity_level_1[lang]}`,
                    },
                    {
                      value: '2',
                      label: `${l10n.complexity_level_2[lang]}`,
                    },
                  ]}
                  onChange={x => setComplexityLevel(x.target.value)}
                  value={complexityLevel}
                />
              </Col>
            )}
            <Col col={12} md={6} style={{ marginTop: '24px' }}>
              <Row>
                <Col>
                  <InvestmentRisk el="p" styleName="label">
                    {l10n.performanceStartDate[lang]}
                  </InvestmentRisk>
                </Col>
                <Col>
                  <DatePicker
                    // @ts-ignore
                    dateFormat="MM/yyyy"
                    name="performance-start-period"
                    maxDate={
                      new Date(
                        currentModelPortfolio.rawHistoricalPerformance[
                          currentModelPortfolio.rawHistoricalPerformance
                            .length - 2
                        ].date
                      )
                    }
                    minDate={
                      new Date(
                        currentModelPortfolio.rawHistoricalPerformance[0].date
                      )
                    }
                    // @ts-ignore
                    onChange={(value: any) =>
                      setPerformanceStartDate(new Date(value).toString())
                    }
                    showMonthYearPicker
                    selected={new Date(performanceStartDate)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col col={12} md={12} xl={6}>
          <Row xlJustifyContent="end">
            <Col col={12} md={6}>
              <Row>
                <Col style={{ display: 'flex' }}>
                  <LineChartLegendColorMfm />
                  <LineChartLegend>
                    {`${l10n.perf_linechart_legend_moneyfarm[lang]} ${riskLevel}`}
                  </LineChartLegend>
                </Col>
              </Row>
              <Row>
                <Col col={5}>
                  <AnnualisedPerformancePercentage>
                    {getPercentage(locale, mfmOverallPerformance)}{' '}
                  </AnnualisedPerformancePercentage>
                  <Text el="p" styleName="bodyRegular" variant="secondary">
                    {l10n.all_time[lang]}
                  </Text>
                </Col>
                <Col col={7}>
                  <OverallPerformancePercentage>
                    {getPercentage(locale, mfmAnnualisedPerformance)}
                  </OverallPerformancePercentage>
                  <Text el="p" styleName="bodyRegular" variant="secondary">
                    {l10n.average_annual[lang]}
                  </Text>
                </Col>
              </Row>
            </Col>
            {isBenchmarkEnabled() && (
              <>
                <Col col={12} md={6}>
                  <Row>
                    <Col style={{ display: 'flex' }}>
                      <LineChartLegendColorBenchmark />
                      <LineChartLegend>
                        {l10n.perf_linechart_legend_benchmark[lang]}
                      </LineChartLegend>
                    </Col>
                  </Row>
                  <Row>
                    <Col col={5}>
                      <AnnualisedPerformancePercentage>
                        {getPercentage(locale, benchmarkOverallPerformance)}
                      </AnnualisedPerformancePercentage>
                      <Text el="p" styleName="bodyRegular" variant="secondary">
                        {l10n.all_time[lang]}
                      </Text>
                    </Col>
                    <Col col={7}>
                      <OverallPerformancePercentage>
                        {getPercentage(locale, benchmarkAnnualisedPerformance)}
                      </OverallPerformancePercentage>
                      <Text el="p" styleName="bodyRegular" variant="secondary">
                        {l10n.average_annual[lang]}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </ControlsAndStatsContainer>
  )
}

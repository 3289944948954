import React, { FC } from 'react'
import { Col } from 'styled-bootstrap-grid'

import { GRID_UNIT, Margin, Switch } from 'mf-ui-components'

import { useChartState } from './chartState'
import { StyledRow, SwitchLabel } from './InvestmentTypeControl.styles'
import rawlang, * as l10n from '../../../../utils/l10n'
import ESGLeafSVG from '../../../../images/esg-leaf.svg'

const lang = rawlang as 'it' | 'en'

export const InvestmentTypeControl: FC = () => {
  const {
    investmentStyle,
    setInvestmentStyle,
    setComplexityLevel,
  } = useChartState()

  return (
    <Margin bottom={GRID_UNIT * 6}>
      <Col col={12} md={12} xl={12}>
        <StyledRow>
          <SwitchLabel>
            <img src={ESGLeafSVG} alt="ESG" />
            <span>{l10n.esg_option[lang]}</span>
          </SwitchLabel>
          <Switch
            checked={investmentStyle === 'esg'}
            onChange={() => {
              setInvestmentStyle(
                investmentStyle === 'classic' ? 'esg' : 'classic'
              )

              // ESG-type portfolios have no risk level-based differentiation,
              // and their reported complexity level is always 1 in our data.
              setComplexityLevel(1)
            }}
          />
        </StyledRow>
      </Col>
    </Margin>
  )
}

import { useCallback } from 'react'

import { locale } from './libs'
import { useIsViewportBelow } from '../../../../utils/useIsViewportBelow'
import gridTheme from '../../../../utils/gridTheme'

export function useDateFormatter() {
  const isMobileViewport = useIsViewportBelow(gridTheme.breakpoints.md)

  const formatDateLabel = useCallback(
    (date: number | Date) => {
      const year = Intl.DateTimeFormat(locale, {
        year: 'numeric',
      }).format(date)

      if (!isMobileViewport) return year

      return `’${year.substring(2, 4)}`
    },
    [locale, isMobileViewport]
  )

  return formatDateLabel
}

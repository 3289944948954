// @ts-expect-error
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030'
import rawlang, * as l10n from './l10n'
import { InvestmentStyle, ModelPortfolio } from '../types/ModelPortfolio'

const lang = rawlang as 'it' | 'en'

export type PerformanceRecord = {
  date: string
  performance: number
}

export type Benchmark = Partial<ModelPortfolio> & {
  annualisedPerformance: number
  overallPerformance: number
  comparedRiskLevels?: number[]
  type?: 'benchmark'
}

export type RiskLevelData = Pick<
  ModelPortfolio,
  'id' | 'riskLevel' | 'historicalPerformance' | 'rawHistoricalPerformance'
>

export const calculatePerformance = (
  performanceNew: number,
  performanceOld: number
) => performanceNew / performanceOld - 1

const normalisePerformance = (
  pHistoryRecord: Pick<PerformanceRecord, 'performance'>
) => ({
  ...pHistoryRecord,
  performance: (pHistoryRecord.performance - 100) / 100,
})

export const getAnnualisedHistoricalPerformanceForPeriod = (
  firstPerformanceHistoryRecord: PerformanceRecord,
  lastPerformanceHistoryRecord: Pick<PerformanceRecord, 'performance' | 'date'>
) => {
  const first = {
    date: firstPerformanceHistoryRecord.date,
    performance: 100,
  }
  const last = {
    date: lastPerformanceHistoryRecord.date,
    performance:
      (lastPerformanceHistoryRecord.performance * 100) /
      firstPerformanceHistoryRecord.performance,
  }

  const normalizedLast = normalisePerformance(last)

  const timeElapsed = moment(last.date).diff(first.date, 'days')

  const annualisedPerformance =
    timeElapsed < 365
      ? normalizedLast.performance
      : (normalizedLast.performance + 1) ** (365 / timeElapsed) - 1

  return {
    annualisedPerformance: annualisedPerformance * 100,
    overallPerformance: normalizedLast.performance * 100,
  }
}

export const getTableData = (
  mfmPortfolio: Pick<ModelPortfolio, 'rawHistoricalPerformance'>,
  benchmarkPortfolio?: Benchmark
) => {
  const mfmPerformanceList = getLastTwelveMonthsPerformance(
    mfmPortfolio.rawHistoricalPerformance
  )
    .concat(getYearPerformance(mfmPortfolio.rawHistoricalPerformance))
    .map(x => ({
      year: x.year,
      mfmPerformance: x.performance,
    }))

  if (benchmarkPortfolio?.rawHistoricalPerformance) {
    const benchmarkPerformanceList = getLastTwelveMonthsPerformance(
      benchmarkPortfolio.rawHistoricalPerformance
    ).concat(getYearPerformance(benchmarkPortfolio.rawHistoricalPerformance))

    return mfmPerformanceList.map(x => ({
      year: x.year,
      mfmPerformance: x.mfmPerformance,
      benchmarkPerformance: benchmarkPerformanceList.find(
        ({ year }) => year === x.year
      )!.performance,
    }))
  }

  return mfmPerformanceList
}

const getLastTwelveMonthsPerformance = (
  historicalPerformance: PerformanceRecord[]
) => {
  const latestDateAvailable = new Date(
    historicalPerformance[historicalPerformance.length - 1].date
  )

  const dateTwelveMonthsBeforeLatestAvailable = new Date(
    latestDateAvailable.setFullYear(latestDateAvailable.getFullYear() - 1)
  )
    .toISOString()
    .slice(0, 10)

  const performanceLatestAvailable =
    historicalPerformance[historicalPerformance.length - 1].performance

  const performanceTwelveMonthsBeforeLatestAvailable = historicalPerformance.filter(
    element => element.date >= dateTwelveMonthsBeforeLatestAvailable
  )[0].performance

  return [
    {
      year: l10n.last_twelve_months[lang],
      performance: calculatePerformance(
        performanceLatestAvailable,
        performanceTwelveMonthsBeforeLatestAvailable
      ),
    },
  ]
}

const getYearPerformance = (
  historicalPerformance: PerformanceRecord[]
): {
  performance: PerformanceRecord['performance']
  year: string
}[] =>
  historicalPerformance
    .reduce((acc, curr) => {
      if (
        acc.length === 0 ||
        new Date(acc[acc.length - 1].date).getFullYear() !==
          new Date(curr.date).getFullYear()
      )
        return acc.concat(curr)

      return acc
    }, [] as PerformanceRecord[])
    .concat(historicalPerformance[historicalPerformance.length - 1])
    .reduce(
      (acc, curr, index, arr) => {
        const currYear = new Date(curr.date).getFullYear().toString()

        if (index !== arr.length - 1) {
          const yearPerformance = calculatePerformance(
            arr[index + 1].performance,
            curr.performance
          )

          return acc.concat({
            year:
              currYear === new Date().getFullYear().toString()
                ? l10n.year_to_date[lang]
                : currYear,
            performance: yearPerformance,
          })
        }
        return acc
      },
      [] as {
        performance: PerformanceRecord['performance']
        year: string
      }[]
    )
    .reverse()

export const prepareHistoricalPerformance = (
  historicalPerformance: PerformanceRecord[],
  performanceStartDate: PerformanceRecord['date']
) => {
  // Filter out performance data points that are older than the performance
  // start date.
  const filteredAndMappedHistoricalPerformance = historicalPerformance
    .filter(
      ({ date }) => date >= moment(performanceStartDate).format('YYYY-MM-DD')
    )
    .map(({ performance, date }, _, xs) => ({
      x: date,
      y: (performance * 100) / xs[0].performance - 100,
    }))

  // Extract first and last performance data points, so that they're not
  // cut off by the re-sampling.
  const first = filteredAndMappedHistoricalPerformance[0]
  const last =
    filteredAndMappedHistoricalPerformance[
      filteredAndMappedHistoricalPerformance.length - 1
    ]

  // Re-sample data so that only one each 14 points is displayed.
  const resampled = filteredAndMappedHistoricalPerformance.reduce(
    (resampledData, performanceDatum) =>
      moment(performanceDatum.x).diff(
        resampledData[resampledData.length - 1].x,
        'days'
      ) >= 14
        ? resampledData.concat(performanceDatum)
        : resampledData,
    [first]
  )

  return [first, ...resampled.slice(1, resampled.length - 1), last]
}

export const getModelPortfoliosJsonUrl = () =>
  `${
    process.env.GATSBY_ASSETS_CDN
  }/model-portfolios/${process.env.GATSBY_COUNTRY!.toLowerCase()}${
    process.env.GATSBY_COUNTRY === 'GB' ? '_net' : ''
  }.json`

export const getAssetsBreakdownJsonUrl = () =>
  `${process.env.GATSBY_ASSETS_CDN}/model-portfolios/mfm${process.env.GATSBY_COUNTRY}.json`

export const getDefaultAnnualisedPerformance = (
  modelPortfolio: Pick<ModelPortfolio, 'rawHistoricalPerformance'>, // NOTE: This can also be a benchmark.
  performanceStartDate?: PerformanceRecord['date']
): Benchmark =>
  getAnnualisedHistoricalPerformanceForPeriod(
    getFirstItemHistoricalPerformance(
      modelPortfolio.rawHistoricalPerformance,
      performanceStartDate
    ),
    getLastRecordHistoricalPerformance(modelPortfolio.rawHistoricalPerformance)
  )

export const filterHistoricalPerformance = (
  modelPortfolio: ModelPortfolio
): RiskLevelData => ({
  ...modelPortfolio,
  rawHistoricalPerformance: modelPortfolio.historicalPerformance,
  historicalPerformance: modelPortfolio.historicalPerformance.reduce(
    (
      performanceData: PerformanceRecord[],
      performanceDatum: PerformanceRecord
    ) =>
      moment(performanceDatum.date).diff(
        performanceData[performanceData.length - 1].date,
        'days'
      ) >= 14
        ? performanceData.concat(performanceDatum)
        : performanceData,
    [modelPortfolio.historicalPerformance[0]]
  ),
})

function buildModelPortfolioId(
  country: string,
  risk: number,
  complexity: number,
  investmentStyle: InvestmentStyle
) {
  return ['moneyfarm']
    .concat(
      investmentStyle === 'esg' ? 'esg' : '',
      country.toLowerCase(),
      `r${risk}`,
      `c${complexity}`
    )
    .filter(Boolean)
    .join('-')
}

export const findModelPortfolio = (
  country: string,
  risk: number,
  complexity: number,
  investmentStyle: InvestmentStyle
) => (modelPortfolio: Pick<ModelPortfolio, 'id'>) =>
  modelPortfolio.id ===
  buildModelPortfolioId(country, risk, complexity, investmentStyle)

/**
 * @param historicalPerformance
 * @param performanceStartDate
 * @returns The first item of the historical performance array that is after the `performanceStartDate`.
 * If `performanceStartDate` is not provided, the first item of the historical performance array is returned.
 */
export const getFirstItemHistoricalPerformance = (
  historicalPerformance: PerformanceRecord[],
  performanceStartDate?: PerformanceRecord['date']
): PerformanceRecord =>
  performanceStartDate
    ? historicalPerformance.filter(
        ({ date }) => date >= moment(performanceStartDate).format('YYYY-MM-DD')
      )[0]
    : historicalPerformance[0]

const getLastRecordHistoricalPerformance = (
  historicalPerformance: PerformanceRecord[]
) => historicalPerformance[historicalPerformance.length - 1]

export function extractBenchmarkData(
  data: Benchmark[] | RiskLevelData[]
): Benchmark[] {
  return ((data as unknown) as Benchmark[]).filter(
    ({ type }) => type === 'benchmark'
  )
}

export function findRiskLevelBenchmark(
  data: Benchmark[],
  riskLevel: number
): Benchmark | undefined {
  return data.find((datum: Benchmark) =>
    datum.comparedRiskLevels?.includes(riskLevel)
  )
}

import { graphql } from 'gatsby'
import { Alert, GRID_UNIT, Tabs, Text } from 'mf-ui-components'
import React, { useState, useEffect, FC } from 'react'
import { Row, Col, Container } from 'styled-bootstrap-grid'

import rawlang, * as l10n from '../../../../utils/l10n'
import { KeyToTheFigures } from './KeyToTheFigures'
import { useIsViewportBelow } from '../../../../utils/useIsViewportBelow'
import gridTheme from '../../../../utils/gridTheme'
import { ChartSection } from './ChartSection'
import { useModelPortfolios } from './useModelPortfolios'
import { RiskLevelsLabels } from './RiskLevelsLabels'
import { ChartStateProvider, useChartState } from './chartState'
import {
  LoadingPane,
  ModelPortfoliosContainer,
  Spinner,
  StyledCard,
  TabWrapper,
} from './ModelPortfolios.styles'
import { DEFAULT_RISK_LEVEL, RISK_LEVELS } from './libs'

const lang = rawlang as 'it' | 'en'

type Props = {
  marginBottom: unknown
  hiddenAt: unknown
}

const ModelPortfolios: FC<Props> = ({ marginBottom, hiddenAt }) => {
  // Used to identify itself in a later attempt to scroll to the central
  // portfolio.
  const [componentId] = useState<string>(
    Math.ceil(Math.random() * Date.now()).toString(36)
  )
  const isMobileViewport = useIsViewportBelow(gridTheme.breakpoints.md)

  const { setRiskLevel } = useChartState()

  const {
    modelPortfolios,
    defaultModelPortfolio,
    riskLevelData,
    error,
    isLoaded,
  } = useModelPortfolios()

  // Automatically scroll to the central portfolio, keeping its tab centered,
  // only on mobile.
  useEffect(() => {
    if (
      typeof document === 'undefined' ||
      typeof window === 'undefined' ||
      !isLoaded
    )
      return

    const containerCard = document.getElementById(componentId)

    if (!containerCard) return

    const tabsNav = containerCard?.getElementsByClassName(
      'rc-tabs-nav-animated'
    )[0]

    const tab = containerCard?.getElementsByClassName('rc-tabs-tab')[0]

    if (!tabsNav || !tab) return

    if (!isMobileViewport)
      tabsNav.setAttribute('style', 'transform: translate3d(0px, 0px, 0px);')
    else
      tabsNav.setAttribute(
        'style',
        `transform: translate3d(-${Math.floor(
          containerCard.getBoundingClientRect().width / 2 -
            Math.floor(tab.getBoundingClientRect().width) / 2
        )}px, 0px, 0px);`
      )
  }, [componentId, isLoaded, isMobileViewport])

  if (error)
    return (
      <Container>
        <Row justifyContent="center">
          <Col col={10}>
            <Alert
              type="error"
              message={
                <Text el="p" styleName="label">
                  {l10n.data_loading_error[lang]}
                </Text>
              }
            />
          </Col>
        </Row>
      </Container>
    )

  return (
    <ModelPortfoliosContainer marginBottom={marginBottom} hiddenAt={hiddenAt}>
      <StyledCard id={componentId}>
        <Tabs
          stretch
          defaultActiveKey={`${DEFAULT_RISK_LEVEL}`}
          onChange={(riskLevel: string) => setRiskLevel(Number(riskLevel))}
          destroyInactiveTabPane
        >
          {RISK_LEVELS.map(portfolio => (
            /* @ts-ignore */
            <Tabs.TabPane tab={portfolio} key={portfolio.toString()}>
              <TabWrapper>
                <RiskLevelsLabels />

                {!isLoaded ? (
                  <LoadingPane>
                    <Spinner size={GRID_UNIT * 2} />
                  </LoadingPane>
                ) : (
                  <ChartSection
                    modelPortfolios={modelPortfolios}
                    defaultModelPortfolio={defaultModelPortfolio}
                    riskLevelData={riskLevelData}
                  />
                )}

                <KeyToTheFigures />
              </TabWrapper>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </StyledCard>
    </ModelPortfoliosContainer>
  )
}

const Main: FC<Props> = props => (
  <ChartStateProvider>
    <ModelPortfolios {...props} />
  </ChartStateProvider>
)

export default Main

export const query = graphql`
  fragment modelPortfoliosFields on WPGraphQL_MfblocksModelPortfoliosBlock {
    name
    attributes {
      marginBottom
      hiddenAt
    }
  }
`

import { Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'

import { fromSize } from 'mf-ui-components'

import { fontSize } from '../../../../utils/style/fontSize'
import { spacing } from '../../../../utils/ui/spacing'

export const StyledRow = styled(Row).attrs(() => ({
  alignItems: 'center',
}))`
  gap: ${spacing('md')};
  width: 100%;
  justify-content: center;

  ${fromSize('md')} {
    justify-content: end;
  }
`

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  flex-direction: row;

  span {
    font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
    font-size: ${fontSize('normal')};
  }
`

export const ASSETS_BREAKDOWN_SORTING = {
  assetClasses: [
    'cash-and-short-term-government-bonds',
    'developed-markets-government-bonds',
    'inflation-linked-bonds',
    'investment-grade-corporate-bonds',
    'high-yield-and-emerging-markets-bonds',
    'developed-markets-equity',
    'emerging-markets-equity',
    'commodities-and-real-estate',
    'cash',
  ],
  geographicalAllocation: [
    'eurozone',
    'united-states',
    'united-kingdom',
    'japan',
    'china',
    'emerging-ex-china',
    'other-developed',
  ],
  sectorialAllocation: [
    'technology',
    'utilities',
    'finance',
    'energy',
    'telecommunications',
    'consumer-non-cyclicals',
    'industrials',
    'consumer-cyclicals',
    'non-energy-materials',
    'non-corporate',
    'other',
  ],
}

export const ASSETS_BREAKDOWN_TYPES = [
  'assetClasses',
  'sectorialAllocation',
  'geographicalAllocation',
] as const

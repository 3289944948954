import React from 'react'

import styled, { css } from 'styled-components'
import lang, * as l10n from '../../../../utils/l10n'

const ChartTooltip = styled.div`
  margin-bottom: 7px;
  padding: 4px;
  background: ${({ theme }) => theme.tokens.space.surface.regular};
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: normal;
  border-radius: 2px;
  color: ${({ theme }) => theme.tokens.text.label.light};
  box-shadow: 0px 1px 4px
    ${({ theme }) => theme.tokens.interface.shadow.regular};
`
const Point = styled.span`
  position: absolute;
  display: block;
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  background: ${({ color }) => color};
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.tokens.space.surface.regular};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 2px 8px rgba(0, 0, 0, 0.1);
`
const Square = styled.span`
  width: 14px;
  height: 14px;
  background: ${({ color }) => color};
  border-radius: 2px;
  margin-right: 8px;
`
const ValueY = styled.span`
  font-family: ${({ theme }) => theme.typography.font.family.text};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: ${({ theme }) => theme.tokens.text.label.regular};
`
const thinText = css`
  font-family: ${({ theme }) => theme.typography.font.family.text};
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
`
const Label = styled.span`
  ${thinText}
`
const ValueX = styled.span`
  ${thinText}
  margin-top: 4px;
`

export const LineChartTooltip = ({ color, textX, textY }) => (
  <>
    <ChartTooltip>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Square color={color} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Label>
            {l10n.all_time[lang]} <ValueY>{textY}</ValueY>
          </Label>
          <ValueX>{textX}</ValueX>
        </div>
      </div>
    </ChartTooltip>
    <Point color={color} />
  </>
)

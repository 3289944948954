import { getModelPortfoliosJsonUrl } from '../../../../utils/modelPortfolios'
import { getLocaleFromCountry } from '../../../../utils/locale'
import { ModelPortfolio } from '../../../../types/ModelPortfolio'

export const getPercentage = (locale: string, val: string | number) =>
  `${Intl.NumberFormat(locale).format(Number(Number(val).toFixed(1)))}%`

export const isBenchmarkEnabled = () => process.env.GATSBY_COUNTRY === 'GB'

export const country = process.env.GATSBY_COUNTRY || 'GB'

export const locale = getLocaleFromCountry(country)

export const retrieveModelPortfoliosData = async (): Promise<ModelPortfolio[]> => {
  return fetch(getModelPortfoliosJsonUrl()).then(res => res.json())
}

export const DEFAULT_RISK_LEVEL = 4

export const DEFAULT_COMPLEXITY_LEVEL = 1

export const RISK_LEVELS = [1, 2, 3, 4, 5, 6, 7] as const

import React, {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
} from 'react'

import { DEFAULT_COMPLEXITY_LEVEL, DEFAULT_RISK_LEVEL } from './libs'
import { InvestmentStyle } from '../../../../types/ModelPortfolio'

const DEFAULT_CONTEXT_SHAPE = {
  riskLevel: 0,
  setRiskLevel: () => {},

  complexityLevel: 0,
  setComplexityLevel: () => {},

  investmentStyle: 'classic',
  setInvestmentStyle: () => {},

  performanceStartDate: undefined,
  setPerformanceStartDate: () => {},
} as const

const ChartStateContext = createContext<{
  riskLevel: number
  setRiskLevel: Dispatch<number>

  complexityLevel: number
  setComplexityLevel: Dispatch<number>

  investmentStyle: InvestmentStyle
  setInvestmentStyle: Dispatch<InvestmentStyle>

  performanceStartDate: string | undefined
  setPerformanceStartDate: Dispatch<string>
}>(DEFAULT_CONTEXT_SHAPE)

export const ChartStateProvider = ({ children }: { children: ReactNode }) => {
  const [riskLevel, setRiskLevel] = useState<number>(DEFAULT_RISK_LEVEL)
  const [complexityLevel, setComplexityLevel] = useState<number>(
    DEFAULT_COMPLEXITY_LEVEL
  )
  const [investmentStyle, setInvestmentStyle] = useState<InvestmentStyle>(
    'classic'
  )
  const [performanceStartDate, setPerformanceStartDate] = useState<string>()

  return (
    <ChartStateContext.Provider
      value={{
        riskLevel,
        setRiskLevel,

        complexityLevel,
        setComplexityLevel,

        investmentStyle,
        setInvestmentStyle,

        performanceStartDate,
        setPerformanceStartDate,
      }}
    >
      {children}
    </ChartStateContext.Provider>
  )
}

export const useChartState = () => {
  const context = useContext(ChartStateContext)

  if (context === undefined)
    throw new Error('useChartState must be used within a ChartStateProvider')

  return context
}

import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'

import { Text } from 'mf-ui-components'

import rawlang, * as l10n from '../../../../utils/l10n'

const lang = rawlang as 'it' | 'en'

export const RiskLevelsLabels = () => (
  <Row
    style={{
      marginBottom: '24px',
    }}
  >
    <Col col={6}>
      <Text el="div" styleName="bodyRegular" variant="secondary">
        {l10n.low_risk[lang]}
      </Text>
    </Col>
    <Col
      col={6}
      style={{
        textAlign: 'right',
      }}
    >
      <Text el="div" styleName="bodyRegular" variant="secondary">
        {l10n.high_risk[lang]}
      </Text>
    </Col>
  </Row>
)

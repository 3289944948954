import React, { FC } from 'react'
import { DefaultTheme, withTheme } from 'styled-components'

import { NivoLineChart } from 'mf-ui-components'

import { getPercentage } from '../AssetsBreakdown/libs'
import { LineChartTooltip } from './LineChartTooltip'
import { ChartContainer } from './Chart.styles'
import { locale } from './libs'
import { useDateFormatter } from './useDateFormatter'

type Props = {
  mfmDataset: {
    x: string
    y: number
  }[]
  benchmarkDataset: {
    x: string
    y: number
  }[]
  onLineChartMouseMoveHandler: (datum: { x: string }) => void
  onLineChartMouseLeaveHandler: () => void
  theme?: DefaultTheme
}

export const Chart: FC<Props> = withTheme(
  ({
    mfmDataset,
    benchmarkDataset,
    onLineChartMouseMoveHandler,
    onLineChartMouseLeaveHandler,
    theme,
  }) => {
    const formatDateLabel = useDateFormatter()

    return (
      <ChartContainer>
        <NivoLineChart
          chartLines={[
            {
              label: 'mfm',
              color: theme.tokens.chart.primary.main,
              data: mfmDataset,
            },
            {
              label: 'benchmark',
              color: theme.tokens.chart.tertiary.main,
              data: benchmarkDataset,
            },
          ]}
          xAxisFormatter={formatDateLabel}
          xFormat={(date: Date) => date.toLocaleDateString(locale)}
          yAxisFormatter={(x: number) => getPercentage(locale, x)}
          onMouseMove={onLineChartMouseMoveHandler}
          onMouseLeave={onLineChartMouseLeaveHandler}
          // todo: type properly
          tooltip={(x: any) => (
            <LineChartTooltip
              color={x.point.color}
              textX={x.point.data.xFormatted}
              textY={getPercentage(locale, Number(x.point.data.yFormatted))}
            />
          )}
          theme={theme}
        />
      </ChartContainer>
    )
  }
)

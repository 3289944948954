import styled from 'styled-components'

import { GRID_UNIT, Text } from 'mf-ui-components'

export const PortfolioTitle = styled(Text)`
  margin-top: 32px;
  margin-bottom: ${GRID_UNIT}px;
`

export const TableContainer = styled.div`
  padding: 2px;
`
